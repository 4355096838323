import styled, { css } from 'styled-components';
import { breakpointUp, color, spacer, variable } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import { zIndex } from '../../../../theme/functions';
import Collapse from '../../../Collapse/Collapse';
import Icon from '../../../Icon/Icon';
import { Color } from 'types/Color';
import { Spacer } from 'constants/styling';

interface StyledFaqWrapperProps {
  borderColor: Color;
  paddingBottom?: Spacer;
}

export const StyledFaqWrapper = styled.div<StyledFaqWrapperProps>`
  display: flex;
  flex-direction: column;
  border-radius: ${variable('border-radius')};
  transition: ${variable('transition')};
  ${variable('border-thick')};
  border-color: ${({ borderColor }) => color(borderColor)};
  padding-bottom: ${({ paddingBottom }) => paddingBottom && spacer(paddingBottom)};
  width: clamp(50%, 760px, 100%);
`;

export const StyledQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacer(100)} ${spacer(150)};
  z-index: ${zIndex('base')};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      padding: ${spacer(150)} ${spacer(200)};
    `
  )}
`;

export const StyledIcon = styled(Icon)`
  .icon {
    transition: ${variable('transition')};
  }
`;

export const StyledCollapse = styled(Collapse)`
  margin-top: 0px;
  padding: 0px ${spacer(150)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      padding: 0px ${spacer(200)};
    `
  )}

  // ClassName approach to overwrite default link styling
  .contentful-call-to-action {
    color: ${color('primary-300')};
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      color: ${color('primary-400')};
    }
  }
`;
