import { Variants, useReducedMotion } from 'framer-motion';
import { ReactNode } from 'react';
import { StyledCollapse } from './Collapse.styled';

const transition = {
  duration: 0.4,
  ease: [0.15, 1, 0.3, 1],
};

interface CollapseProps {
  children: ReactNode;
  className?: string;
  isCollapsed?: boolean;
  marginTop?: string;
}

const Collapse = ({ children, className, isCollapsed, marginTop = '10px' }: CollapseProps) => {
  const shouldReduceMotion = useReducedMotion();

  const variants: Variants = {
    collapsed: (reduceMotion) => ({
      height: reduceMotion ? 'auto' : 0,
      marginTop: '0px',
      opacity: 0,
      pointerEvents: 'none',
      transitionEnd: { display: 'none' },
    }),
    expanded: () => ({
      display: 'block',
      height: 'auto',
      marginTop,
      opacity: 1,
      pointerEvents: 'auto',
    }),
  } as Variants;

  return (
    <StyledCollapse
      key="collapse"
      animate={isCollapsed ? 'collapsed' : 'expanded'}
      className={className}
      custom={shouldReduceMotion}
      initial={isCollapsed ? 'collapsed' : 'expanded'}
      transition={transition}
      variants={variants}
    >
      {children}
    </StyledCollapse>
  );
};

export default Collapse;
