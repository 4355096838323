import styled, { css } from 'styled-components';
import { breakpointUp, spacer } from 'theme';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';
import { BREAKPOINTS } from 'constants/breakpoints';

interface StyledTileContentWrapperProps {
  backgroundColor?: string;
  hasLink?: boolean;
  textColor?: string;
}

interface StyledImageWrapperProps {
  imagePosition?: 'before' | 'after';
}

export const StyledContent = styled.div<StyledTileContentWrapperProps>`
  display: flex;
  gap: ${spacer(200)};
  width: 100%;
  position: relative;

  img {
    border-radius: ${spacer(75)};
  }
`;

export const StyledImageBlock = styled(ContentfulImageBlock)`
  width: 100%;
`;

export const StyledImageWrapper = styled.div<StyledImageWrapperProps>`
  display: none;
  flex: 0 1 100%;
  position: relative;
  order: ${({ imagePosition }) => imagePosition && imagePosition === 'before' && -1};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
    `
  )}
`;
