import { useState } from 'react';
import { IFaq } from 'types/ContentfulTypes';
import { ICON_NAMES } from '../../../../constants/icon';
import Typography from '../../../Typography/Typography';
import ContentfulFieldRenderer from '../../ContentfulFieldRenderer/ContentfulFieldRenderer';
import { CALL_TO_ACTION_TYPES } from '../types';
import { StyledCollapse, StyledFaqWrapper, StyledIcon, StyledQuestion } from './ContentfulFaq.styled';

interface ContentfulFaqProps {
  content: IFaq;
}

const ContentfulFaqs = ({ content }: ContentfulFaqProps) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!content?.fields) {
    return null;
  }

  const { answer, question } = content.fields;

  return (
    <StyledFaqWrapper borderColor={isOpen ? 'primary-300' : 'whisper-200'} paddingBottom={isOpen ? 100 : undefined}>
      <StyledQuestion onClick={() => setIsOpen(!isOpen)} role="button">
        <Typography>{question}</Typography>
        <StyledIcon
          type="fa"
          color={isOpen ? 'primary-300' : 'black'}
          name={ICON_NAMES.ANGLE_DOWN}
          rotate={isOpen ? 180 : 0}
          size={200}
        />
      </StyledQuestion>

      <StyledCollapse isCollapsed={!isOpen} marginTop="0px">
        <ContentfulFieldRenderer additionalProps={{ type: CALL_TO_ACTION_TYPES.LINK }} richTextContent={answer} />
      </StyledCollapse>
    </StyledFaqWrapper>
  );
};

export default ContentfulFaqs;
