import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Script from 'next/script';
import { IFaq } from 'types/ContentfulTypes';

interface FaqSchemeScriptProps {
  faqItems: IFaq[];
  scriptId: string;
}

const structuredDataListFromFaqItems = (faqItems: IFaq[]) => {
  return faqItems.map(
    (faqItem) =>
      faqItem.fields.question && {
        '@type': 'Question',
        acceptedAnswer: {
          '@type': 'Answer',
          text: documentToHtmlString(faqItem.fields.answer),
        },
        name: faqItem.fields.question,
      }
  );
};

const FaqSchemeScript = ({ faqItems, scriptId }: FaqSchemeScriptProps) => {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: structuredDataListFromFaqItems(faqItems),
  };
  return (
    <Script
      id={`FaqSchemeScript-${scriptId}`}
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      type="application/ld+json"
    />
  );
};
export default FaqSchemeScript;
