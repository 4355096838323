import Typography from 'components/Typography/Typography';
import { ICON_NAMES } from 'constants/icon';
import { FontWeight, Spacer } from 'constants/styling';
import { Color } from 'types/Color';
import {
  AccordionContent,
  AccordionContentProps,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryIcon,
  AccordionWrapper,
} from './Accordion.styled';

type AccordionItem = {
  content: React.ReactNode | React.ReactNode[];
  id: string;
  title: string;
};

interface AccordionProps extends AccordionContentProps {
  className?: string;
  iconColor?: Color;
  iconSize?: Spacer;
  items: AccordionItem[];
  titleFontWeight?: FontWeight;
  withBorders?: boolean;
  withDividers?: boolean;
}

/**
 * @deprecated This component is deprecated and should not be used anymore.
 * Please use Accordion from the ui folder instead.
 */
const Accordion = ({
  backgroundColor,
  className,
  iconColor = 'primary-300',
  iconSize = 75,
  items,
  titleFontWeight,
  withBorders,
  withDividers,
}: AccordionProps) => {
  if (!items.length) return null;

  return (
    <AccordionWrapper className={className}>
      {items.map((item) => (
        <AccordionDetails key={`AccordionDetails-${item.id}`} withBorders={withBorders}>
          <AccordionSummary withDividers={withDividers}>
            <Typography fontWeight={titleFontWeight}>{item.title}</Typography>
            <AccordionSummaryIcon type="fa" name={ICON_NAMES.ANGLE_RIGHT} size={iconSize} color={iconColor} />
          </AccordionSummary>
          <AccordionContent backgroundColor={backgroundColor}>{item.content}</AccordionContent>
        </AccordionDetails>
      ))}
    </AccordionWrapper>
  );
};
export default Accordion;
