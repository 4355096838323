import Accordion from 'components/Accordion/Accordion';
import ContentfulFieldRenderer from 'components/Contentful/ContentfulFieldRenderer/ContentfulFieldRenderer';
import FaqSchemeScript from 'components/Seo/SeoSchemeScripts/FaqSchemeScript/FaqSchemeScript';
import { IGroupingFaqs } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY } from 'types/Image';
import ContentfulFaqs from '../ContentfulFaq/ContentfulFaq';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import { CONTENTFUL_TYPES } from '../types';
import { StyledContent, StyledImageBlock, StyledImageWrapper } from './ContentfulGroupingFaq.styled';

interface ContentfulGroupingFaqsProps {
  content: IGroupingFaqs;
  useSleekDesignFaq?: boolean;
}

const ContentfulGroupingFaqs = ({ content, useSleekDesignFaq }: ContentfulGroupingFaqsProps) => {
  const { image, internalLabel, items, title } = content.fields;

  if (!items?.length) {
    return null;
  }

  const faqs = useSleekDesignFaq ? (
    <Accordion
      iconSize={125}
      withBorders
      titleFontWeight="normal"
      iconColor="whisper-500"
      backgroundColor="white"
      items={items?.map((content) => {
        const { answer, internalLabel, question } = content.fields;
        return {
          content: (
            <ContentfulFieldRenderer
              additionalProps={{ type: CONTENTFUL_TYPES.RICH_TEXT_SIMPLE }}
              richTextContent={answer}
            />
          ),
          id: internalLabel,
          title: question ?? '',
        };
      })}
    />
  ) : (
    <div className="flex w-full flex-col items-center justify-center gap-4">
      {items.map((item) => (
        <ContentfulFaqs key={item?.sys?.id} content={item} />
      ))}
    </div>
  );

  return (
    <ContentfulGroupingWrapper className="items-start">
      <FaqSchemeScript faqItems={items} scriptId={internalLabel} />
      <ContentfulGroupingHeading className="text-center">{title}</ContentfulGroupingHeading>

      {image ? (
        <StyledContent>
          {faqs}
          <StyledImageWrapper>
            <StyledImageBlock content={image} sizes={{ [DEFAULT_SIZE_KEY]: 200 }} />
          </StyledImageWrapper>
        </StyledContent>
      ) : (
        faqs
      )}
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingFaqs;
