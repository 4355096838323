import Icon from 'components/Icon/Icon';
import styled, { css } from 'styled-components';
import { color, spacer, variable } from 'theme';
import { Color } from 'types/Color';

export interface AccordionContentProps {
  backgroundColor?: Color;
}

export interface AccordionDetailsProps {
  withBorders?: boolean;
}

export interface AccordionSummaryProps {
  withDividers?: boolean;
}

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AccordionDetails = styled.details<AccordionDetailsProps>`
  padding: 0 ${spacer(100)};

  &[open] {
    summary .icon {
      transform: rotate(90deg);
    }
  }

  ${({ withBorders }) =>
    withBorders &&
    css`
      ${variable('border')};
      border-bottom: none;

      &:first-of-type {
        border-top-right-radius: ${variable('border-radius-long')};
        border-top-left-radius: ${variable('border-radius-long')};
      }

      &:last-of-type {
        border-bottom-right-radius: ${variable('border-radius-long')};
        border-bottom-left-radius: ${variable('border-radius-long')};
        ${variable('border')};
      }
    `}
`;

export const AccordionSummary = styled.summary<AccordionSummaryProps>`
  display: flex;
  justify-content: space-between;
  padding: ${spacer(100)} 0;

  &:not(:last-child) {
    ${({ withDividers }) =>
      withDividers &&
      css`
        border-bottom: 1px solid ${color('whisper-100')};
      `}
  }
`;

export const AccordionSummaryIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${spacer(100)};

  .icon {
    transition: transform 0.1s ease-in-out;
  }
`;

export const AccordionContent = styled.div<AccordionContentProps>`
  background-color: ${({ backgroundColor }) => backgroundColor && color(backgroundColor)};
`;
